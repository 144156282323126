<template>
  <div class="notifikasi">
    <h1 class="mb-5">Notifikasi</h1>
    <div class="row col-12" v-if="!isLoading">
      <table class="table table-hover">
        <thead class="table-dark">
          <tr>
            <th>Pesan</th>
            <th>Tanggal</th>
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="notif in notifications"
            :key="notif.id"
            style="cursor: pointer"
          >
            <td
              v-if="notif.readed"
              @click="openNotification(notif.id, notif.resource_id)"
            >
              <i class="bi bi-info-circle"></i> {{ notif.message }}
            </td>
            <td v-else @click="openNotification(notif.id, notif.resource_id)">
              <strong
                ><i class="bi bi-info-circle"></i> {{ notif.message }}</strong
              >
            </td>
            <td>{{ notif.createdAt.substring(0, 10) }}</td>
            <td>
              <button
                class="btn btn-secondary btn-sm"
                @click="markAsReaded(notif.id)"
                v-if="!notif.readed"
              >
                Tandai sudah dibaca
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { notificationService } from "@/services";
export default {
  data() {
    return {
      notifications: [],
      isLoading: true,
    };
  },
  methods: {
    async openNotification(id, resource_id) {
      // Update readed notification
      await notificationService.updateNotificationReaded(id);

      // Go to anggota detail
      this.$router.push({
        name: "admin-anggota-detail",
        params: { id: resource_id },
      });
    },
    async markAsReaded(id) {
      // Update readed notification
      await notificationService.updateNotificationReaded(id);
      this.loadData();
    },
    loadData() {
      notificationService
        .getAll()
        .then((resp) => {
          this.notifications = resp.data.rows;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$toast.error(error.message);
        });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
.notifikasi {
  padding: 3rem 1.5rem;
}
</style>
